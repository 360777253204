<template>
    <v-container fluid>
        <v-card>
            <v-card-text>
                <v-row>
                    <v-col v-if="!removeForm" :cols="2">
                        <component
                            :is="mainForm"
                            v-model="item"
                            :is-readonly="!canEdit(claimName)"
                            :filter="filter"
                            @delete="goBack"
                            @save="goBack"
                            @cancel="goBack" />
                    </v-col>
                    <v-col>
                        <edit-view-tabs
                            :tabs="tabs"
                            :get-tab-filter="getTabFilter"
                            :refresh-key="refreshKey" />
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import editViewMixin from "@/mixins/editViewMixin";

export default {
    components: {
        editViewTabs: () => import("@/components/EditViewTabs"),
    },
    mixins: [editViewMixin],
};
</script>
