import axios from "axios";

let editViewMixin = {
    props: {
        filter: {
            type: Object,
            default: null,
        },
        tabs: {
            type: Object,
            default: null,
        },
        claimName: {
            type: String,
            default: "",
        },
        apiMethod: {
            type: String,
            default: "",
        },
        mainForm: {
            type: Object,
            default: null,
        },
        itemId: {
            type: Number,
            default: null,
        },
        refreshKey: {
            type: String,
            default: "",
        },
        hasImport: {
            type: Boolean,
            default: false,
        },
        removeForm: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            id: null,
            item: {},
        };
    },

    async mounted() {
        if (!this.isEmpty(this.item.id)) {
            this.id = this.itemId;
        } else {
            this.id = this.$route.params.id;
            let response = await axios.get("/api/" + this.apiMethod + "/" + this.id);
            this.item = response.data;
        }
    },

    methods: {
        goBack() {
            this.$router.go(-1);
        },
        getTabFilter(value) {
            let filter = this.filter;
            filter[value] = this.id;
            return filter;
        },
        downloadUrl(url, name) {
            fetch(url)
                .then(resp => resp.blob())
                .then(blob => {
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement("a");
                    a.style.display = "none";
                    a.href = url;
                    // the filename you want
                    a.download = name;
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                })
                .catch(() => alert("oh no!"));
        },
    },

    watch: {
        itemId: {
            handler() {
                this.id = this.itemId;
            },
        },
    },
};

export default editViewMixin;
